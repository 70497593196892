import React, { useEffect, useState } from "react";
import iosImage from "../assets/images/ios-badge.svg";
import googlePlayImage from "../assets/images/google-play-badge.svg";
import mobileImage from "../assets/images/opspot-mobile.png";

function Mobile() {
  return (
    <div className="mb-24 bg-white flex flex-col items-center justify-center w-full">
      <div className="py-24">
        <img src={mobileImage} alt="OpSpot Mobile" className="w-112" />
      </div>
      <h2 className="pb-4 px-12 text-lg leading-tight font-bold text-center">
        Apple? Android? We’ve got you covered.
      </h2>
      <p className="text-sm w-3/4 text-center">
        Opspot provides a seamless experience whether you’re using iOS, Android,
        or both.{" "}
        <span className="text-gray-500 block pt-2">
          Limited to Canada and the US
        </span>
        .
      </p>
      <div className="lg:w-1/4 flex justify-center space-x-4 pt-6 mb-24">
        <a href="https://apps.apple.com/ca/app/opspot/id6587551457">
          <div className="flex">
            <img
              src={iosImage}
              alt="Download on the App Store"
              className="min-h-[60px] max-h-[60px] flex-shrink-0 object-contain"
            />
          </div>
        </a>

        <a href="https://play.google.com/store/apps/details?id=com.anonymous.opspot&pcampaignid=web_share">
          {/* Fixed-size container */}
          <div className="flex ">
            <img
              src={googlePlayImage}
              alt="Get it on Google Play"
              className="min-h-[60px] max-h-[60px] flex-shrink-0 object-contain"
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default Mobile;
